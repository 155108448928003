// The hero image can be added to the src folder with the HeroImage.png name.
// Logo image can be added to the src folder as Logo.png
// Favicon image can be added to the public folder as favicon.ico

// Project specific values that can be changed easily.
export const projectConfiguration = {
  // Product Key
  productKey: "simple_soundboard",

  // Content variables
  heroLineOne: "Your Notifications",
  heroLineTwo: "Never Sounded",
  heroLineThree: "So Fun",
  subtitle:
    "Bring your notifications to life with a burst of humor! Sup is the mobile app that surprises your friends with funny sounds - delivered live to their phones",

  // Colors
  backgroundColor: "#0F1118",
  primaryTextColor: "#ffffff",
  secondaryTextColor: "#9FA4BC",
  tertiaryTextColor: "#D0D4EA",
  inputBackgroundColor: "#595D6F",
  inputFocusBackgroundColor: "#ffffff",
  inputTextColor: "#595D6F",
  inputFocusTextColor: "#0D0F14",
  punchColor: "#F504F5", // Used as link color
  footerLinkColor: "#ffffff",
  buttonTextColor: "#ffffff",

  //   Fonts
  primaryFont: "Poppins",
  secondaryFont: "Inter",
};
